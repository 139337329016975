<!--
 * @Description: 图片空间、选择图片
 * @Author: 琢磨先生
 * @Date: 2022-06-01 01:39:00
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-07-26 11:59:29
-->

<template>
  <div class="image_dialog_space_upload" @click="visibleDialog = true">
    <el-icon class="uploader-icon"><Upload /></el-icon>
    <div class="text">上传图片</div>
  </div>
  <el-dialog
    title="图片空间"
    v-model="visibleDialog"
    width="960px"
    custom-class="choose_psd"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="dialogClose"
  >
    <div class="tip">{{ limitObj.tip }}</div>

    <div class="main-body">
      <div class="tools">
        <el-form :model="query" ref="form" :inline="true">
          <el-form-item label="" style="margin-right: 10px">
            <el-input v-model="query.q" placeholder="请输入图片名称"></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-button type="default" icon="search" @click="onSearch"
              >查询</el-button
            >
          </el-form-item>
          <el-form-item label="">
            <el-checkbox @change="usableChange">仅展示可用图片</el-checkbox>
          </el-form-item>
        </el-form>
        <psd-upload :folder="currentFolder" @change="uploadChange"></psd-upload>
      </div>
      <el-row :gutter="20">
        <el-col class="left" :span="6">
          <el-card shadow="never">
            <el-scrollbar>
              <el-tree
                ref="tree"
                :data="folderList"
                node-key="id"
                :expand-on-click-node="false"
                highlight-current
                :props="{ children: 'children', label: 'name' }"
                default-expand-all
                @current-change="treeNodeChange"
              />
            </el-scrollbar>
          </el-card>
        </el-col>
        <el-col class="right data" :span="18" v-loading="loading">
          <el-scrollbar>
            <div class="grid">
              <div
                class="grid_item"
                v-for="item in tableData.data"
                :key="item.id"
                @click="checkToggler(item)"
              >
                <div class="thumb">
                  <el-image
                    :src="item.file_url"
                    :fit="item.is_clipping ? 'fill' : 'scale-down'"
                  />
                </div>
                <div class="info">
                  <div class="name">{{ item.name }}</div>
                  <div class="name">{{ item.width }}x{{ item.height }}</div>
                  <div class="name">{{ item.size_text }}</div>
                </div>

                <label
                  class="status"
                  v-if="selectionList.findIndex((o) => o.id == item.id) > -1"
                >
                  <el-icon><Select /></el-icon>
                </label>
                <el-button
                  class="btn-open-crop"
                  type="text"
                  size="small"
                  @click.stop="openCropDialog(item)"
                  >剪裁</el-button
                >
              </div>
            </div>
          </el-scrollbar>
        </el-col>
      </el-row>
    </div>

    <template #footer>
      <div class="space_footer">
        <div class="folder_tools">
          <el-button @click="addFolder" icon="Folder">创建目录</el-button>
          <div class="help-block" style="margin-left: 10px">
            创建子目录之前请点击选择上级目录
          </div>
        </div>
        <el-pagination
          v-if="tableData.counts > 0"
          @size-change="pageSizeChange"
          @current-change="pageCurrentChange"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :pager-count="5"
          layout="total, prev, pager, next"
          :total="tableData.counts"
        >
        </el-pagination>
        <span class="selected-tip"
          >已选{{ selectionList.length }}个，可选{{ limitObj.count }}个</span
        >
        <el-button type="primary" @click="onConfirm">确定</el-button>
        <el-button @click="onCancel">取消</el-button>
      </div>
    </template>
  </el-dialog>

  <el-dialog title="剪裁图片" v-model="visibleCropper" width="460px">
    <vue-cropper
      ref="cropper"
      :viewMode="1"
      :zoomable="false"
      :src="currentCropFile.file_url"
      :autoCropArea="1"
      :rotatable="false"
      :scalable="false"
      :minContainerHeight="400"
      :aspectRatio="limitObj.aspectRatio"
      alt="Source Image"
    >
    </vue-cropper>
    <template #footer>
      <div class="flex-row flex-between item-center">
        <el-progress
          :percentage="percentage"
          style="width: 300px"
          v-if="saving"
        />
        <span v-if="!saving"></span>
        <el-button type="primary" @click="handleCropper" :loading="saving"
          >确定</el-button
        >
      </div>
    </template>
  </el-dialog>
</template>

<script>
import PsdUpload from "./psd_upload.vue";
// import ObsClient from "@/obs/esdk-obs-browserjs.3.22.3.min.js";
import ObsClient from "esdk-obs-browserjs/src/obs";

import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import md5_util from "@/utils/md5_util.js";

export default {
  components: {
    VueCropper,
    PsdUpload,
  },
  data() {
    return {
      loading: true,
      //剪裁保存中
      saving: false,
      visibleDialog: false,
      //显示剪裁
      visibleCropper: false,
      //选择的图片列表
      selectionList: [],
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
        types: [1],
        father_id: "",
        width_range: {
          start: 0,
          end: 0,
        },
        height_range: {
          start: 0,
          end: 0,
        },
      },
      //文件目录
      folderList: [],
      tableData: {
        counts: 0,
      },
      //限制的条件
      limitObj: {
        //限制总数,默认10张
        count: 10,
        //限制单张大小,默认3M
        size: 3145728,
        size_text: "3M",
        //宽高比，默认1：1
        aspectRatio: 1.0,
        tip: "",
      },
      //当前待剪裁的图片文件对象
      currentCropFile: null,
      //剪裁上传进度
      percentage: 0,
      //选中的文件目录对象（上传图片存储的目录）
      currentFolder: null,
    };
  },
  emits: ["change"],
  //type:选择的图片类型，1：秘鲁为{1：1}图片 图片空间（宽高比例为1:1或3:4，且宽高均大于480px，大小3M内）
  props: ["type", "count", "hook"],
  watch: {
    type: {
      handler() {
        this.limitObj.tip = "支持jpg、png、jpeg格式图片，且图片大小不能超过3M";
        if (this.type == 1) {
          this.limitObj.tip = "宽高比例为1:1，且宽高均大于480px，大小3M内";
          this.limitObj.aspectRatio = 1.0;
        } else if (this.type == 2) {
          this.limitObj.tip = "宽高比例为4:3，且宽高均大于480px，大小3M内";
          this.limitObj.aspectRatio = 4 / 3;
        }
      },
      immediate: true,
    },
    //限制
    count: {
      handler() {
        if (typeof this.count == "number") {
          this.limitObj.count = this.count;
        } else {
          this.limitObj.count = parseInt(this.count);
        }
      },
      immediate: true,
    },
  },
  created() {
    var settings = this.$store.getters.getSettings;
    this.bucketName = settings.obs_bucket_name;
    this.obs_cdn = settings.obs_cdn;
    this.obs = new ObsClient({
      access_key_id: settings.obs_key_id,
      secret_access_key: settings.obs_access_key,
      server: settings.obs_server,
      timeout: 60 * 5,
    });

    this.loadFolders();
    this.loadData();
  },
  methods: {
    /**
     * 加载文件目录
     */
    loadFolders() {
      this.$http.get("seller/v1/psd/folder").then((res) => {
        if (res.code == 0) {
          this.folderList = [
            {
              id: "",
              name: "文件库",
              children: res.data,
            },
          ];
          this.$nextTick(() => {
            if (this.$refs.tree) {
              this.$refs.tree.setCurrentKey("");
              this.currentFolder = this.folderList[0];
            }
          });
        }
      });
    },
    /**
     * tree 文件目录选择
     */
    treeNodeChange(node) {
      this.currentFolder = node;
      this.query.pageIndex = 1;
      this.query.father_id = node.id;
      this.loadData();
    },
    /**
     * 仅展示可用的图片
     */
    usableChange(val) {
      this.query.pageIndex = 1;
      if (val) {
        this.query.height_range = {
          start: 480,
        };
        this.query.width_range = {
          start: 480,
        };
        this.query.size_range = {
          end: 1024 * 1024 * 3,
        };
      } else {
        delete this.query.width_range;
        delete this.query.width_range;
        delete this.query.size_range;
      }
      this.loadData();
    },
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.$http.post("seller/v1/psd/file", this.query).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    /**
     * 点击图片选择
     */
    checkToggler(item) {
      if (this.selectionList.length >= this.limitObj.count) {
        this.$message({
          type: "error",
          message: `最多只能选择${this.limitObj.count}张图片`,
        });
        return;
      }
      var i = this.selectionList.findIndex((o) => o.id == item.id);
      if (i > -1) {
        this.selectionList.splice(i, 1);
      } else {
        //如果type==0 或者不存在，则不剪裁
        if (this.type == 0 || !this.type) {
          this.selectionList.push(item);
        } else {
          var aspectRatio = item.width / item.height;
          if (this.limitObj.aspectRatio == aspectRatio) {
            this.selectionList.push(item);
          } else {
            this.openCropDialog(item);
          }
        }
      }
    },

    /**
     * 打开剪裁
     */
    openCropDialog(item) {
      console.log(item);
      //剪裁对象
      this.currentCropFile = item;
      //显示剪裁框
      this.visibleCropper = true;
      if (this.$refs.cropper) {
        this.$refs.cropper.replace(item.file_url);
      }
    },

    /**
     * 确定
     */
    onConfirm() {
      if (this.selectionList.length <= 0) {
        this.$message({
          message: "至少选择一张图片",
        });
        return;
      }
      this.visibleDialog = false;
      this.$emit("change", this.selectionList, this.hook);
    },
    /**
     * 取消选择
     */
    onCancel() {
      this.visibleDialog = false;
    },
    /**
     * dialog 关闭
     */
    dialogClose() {
      this.selectionList = [];
    },
    /**
     * 创建目录
     */
    addFolder() {
      this.$prompt(
        "请输入目录名称,上级目录为:" + this.currentFolder.name,
        "创建目录",
        {
          inputPattern: /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/,
          inputErrorMessage: "请输入名称",
        }
      )
        .then((obj) => {
          this.$http
            .post("seller/v1/psd/folder/edit", {
              father_id: this.currentFolder.id,
              name: obj.value,
            })
            .then((res) => {
              if (res.code == 0) {
                res.data.children = [];
                this.currentFolder.children.push(res.data);
              }
            });
        })
        .catch(() => {});
    },
    /**
     * 确定剪裁
     */
    handleCropper() {
      this.$refs.cropper
        .getCroppedCanvas({
           fillColor: "#fff",
        })
        .toBlob(async (blob) => {
          var data = this.$refs.cropper.getData();
          var model = {
            height: parseInt(data.height + ""),
            width: parseInt(data.width + ""),
            size: blob.size,
            is_clipping: true,
            psd_type: 1,
            name: "",
            father_id: this.currentFolder ? this.currentFolder.id : "",
            ext: `.${blob.type.replace("image/", "")}`,
          };
          if (this.currentCropFile) {
            var i = this.currentCropFile.name.lastIndexOf(".");
            model.name =
              this.currentCropFile.name.substring(0, i) + "_副本" + model.ext;
          } else {
            model.name = new Date().getTime() + model.ext;
          }
          //获取md5值
          var md5 = await md5_util.fileMd5(blob);
          model.md5 = md5;

          this.saving = true;
          var res = await this.$http.get(`seller/v1/psd/file/dtl?md5=${md5}`);
          if (res.code == 0) {
            if (res.data) {
              this.visibleCropper = false;
              this.saving = false;
              this.insertToData(res.data);
              return;
            }
          }
          //上传剪裁的图片到服务器
          blob.name = model.name;

          this.$http.get("/common/filename").then(async (res) => {
            if (res.code == 0) {
              var result = await this.obs.putObject({
                Bucket: this.bucketName,
                Key: res.data + model.ext,
                SourceFile: blob,
                ProgressCallback: (transferredAmount, totalAmount) => {
                  var percent = (transferredAmount * 100.0) / totalAmount;
                  this.percentage = parseFloat(percent.toFixed(2));
                },
              });
              if (result.CommonMsg.Status < 300) {
                var cdn_url = `${this.obs_cdn}/${res.data}${model.ext}`;
                model.file_url = cdn_url;
                this.$http.post("seller/v1/psd/file/add", model).then((res) => {
                  if (res.code == 0) {
                    this.insertToData(res.data);
                    this.visibleCropper = false;
                  }
                  this.saving = false;
                });
              }
            }
          });
        });
    },

    /**
     * 将剪裁保存后的对象添加至列表,同时选中
     */
    insertToData(item) {
      var i = this.tableData.data.findIndex((o) => o.id == item.id);
      this.tableData.data.splice(i, 0, item);
      if (this.selectionList.length < this.limitObj.count) {
        this.selectionList.push(item);
      }
    },

    /**
     * 图片上传成功后回调
     * @param {*} files
     */
    uploadChange(file) {
      console.log(file);
      this.tableData.data.push(file);
    },
  },
};
</script>

<style  >
.image_dialog_space_upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
}

.image_dialog_space_upload:hover {
  border-color: var(--el-color-primary);
}

.image_dialog_space_upload .text {
  font-size: 12px;
  color: #c0c4cc;
  line-height: 12px;
  margin-top: 10px;
}

.image_dialog_space_upload .el-icon.uploader-icon {
  font-size: 26px;
  color: #c0c4cc;
  text-align: center;
}
/* 弹出框样式 */
.choose_psd .tip {
  line-height: 14px;
  font-size: 14px;
  color: var(--text-tip-color);
}

.choose_psd .main-body {
  height: 500px;
  display: flex;
  flex-direction: column;
}
.choose_psd .main-body .el-row {
  flex-grow: 1;
  height: 1px;
}
/* 工具条 */
.choose_psd .tools {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 0;
  justify-content: space-between;
}

.choose_psd .main-body .el-col {
  height: 100%;
}
.choose_psd .main-body .left .el-card {
  height: 100%;
}
.choose_psd .main-body .el-col.right {
  flex: 1;
  width: 1px;
}
/* grid列表 */
.choose_psd .main-body .grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 20px;
  row-gap: 20px;
}
.choose_psd .main-body .grid_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid white;
  transition: all 0.5s;
  width: 120px;
  position: relative;
  overflow: hidden;
}
.choose_psd .main-body .grid_item:hover {
  border-color: var(--el-color-primary);
}
/* 缩略图 */
.choose_psd .grid_item .thumb {
  /* width: 118px; */
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url("../../assets/transparency.png") repeat;
}
.choose_psd .grid_item .thumb img {
  max-height: 100%;
  max-width: 100%;
}

.choose_psd .grid_item .info {
  padding: 10px;
}
.choose_psd .grid_item .name {
  width: 120px;
  font-size: 12px;
  line-height: 14px;
  color: var(--text-tip-color);
  margin: 3px 0;
  overflow: hidden;
  height: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.choose_psd .grid_item .status {
  right: -15px;
  top: -6px;
  width: 40px;
  height: 24px;
  position: absolute;
  background: var(--el-color-success);
  text-align: center;
  transform: rotate(45deg);
}

.choose_psd .grid_item .status .el-icon {
  transform: rotate(-45deg);
  color: white;
  font-size: 11px;
}
/* 图片内的剪裁小按钮 */
.choose_psd .grid_item .btn-open-crop {
  position: absolute;
  z-index: 1;
  bottom: 10px;
  right: 10px;
  display: none;
}

.choose_psd .grid_item:hover .btn-open-crop {
  display: block;
}

/* 选择数量提示 */
.choose_psd .el-dialog__footer .selected-tip {
  margin-right: 20px;
  color: var(--text-tip-color);
}

.choose_psd .el-dialog__body {
  padding-top: 0;
}
.choose_psd .el-dialog__header {
  padding-bottom: 0;
}
.choose_psd .main-body .left .el-card .el-card__body {
  padding: 5px;
  height: 100%;
}

/* 页脚 */
.choose_psd .space_footer {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
/* 分页 */
.choose_psd .el-pagination {
  margin-top: 0;
  padding-top: 0;
}

.choose_psd .space_footer .folder_tools {
  flex-grow: 1;
  display: flex;
}
</style>

 