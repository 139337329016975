<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-06-01 01:18:38
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-06-13 03:16:50
-->
<template>
  <div class="list">
    <draggable
      class="draggable_group"
      v-model="fileList"
      group="people"
      @start="drag = true"
      @end="onDragEnd"
      item-key="id"
    >
      <template #item="{ element, index }">
        <div class="image">
          <el-image
            :src="element.file_url"
            fit="scale-down"
            :preview-src-list="srcList"
            :initial-index="index"
          ></el-image>
          <el-icon class="icon-del" @click="onRemove(index)"
            ><Delete
          /></el-icon>
          <span class="cover_tag" v-if="index == 0">主轮播图</span>
        </div>
      </template></draggable
    >
    <psg-image-space
      @change="chooseChange"
      :count="maxCount - fileList.length"
    ></psg-image-space>
  </div>
  <div class="tip help-block">
    图片要求：宽高比例为1:1,且宽高均大于480px，大小3M内。已上传{{
      fileList.length
    }}/{{ maxCount }}张，拖拽可调整顺序
  </div>
</template>

<script>
import PsgImageSpace from "../../psd/psd_image_space.vue";
import draggable from "vuedraggable";
export default {
  components: {
    PsgImageSpace,
    draggable,
  },
  data() {
    return {
      fileList: [],
      srcList: [],
      maxCount: 10,
      drag: false,
    };
  },
  emits: ["change"],
  props: ["images"],
  watch: {
    images: {
      handler() {
        if (this.images) {
          this.fileList = [];
          if (this.images.length > 0) {
            this.fileList.push(...this.images);
            this.srcList = this.fileList.map((x) => x.file_url);
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 移除图片
     * @param {*} i
     */
    onRemove(i) {
      this.fileList.splice(i, 1);
      this.$emit("change", this.fileList);
    },
    /**
     * 拖拽结束
     */
    onDragEnd() {
      this.$emit("change", this.fileList);
    },
    /**
     * 选择图片回调
     */
    chooseChange(values) {
      if (this.fileList.length >= 10) {
        return;
      }
      if (this.maxCount - values.length - this.fileList.length >= 0) {
        this.fileList = this.fileList.concat(values);
      } else {
        for (var i = 0; i < values.length; i++) {
          if (this.fileList.length >= this.maxCount) {
            break;
          }
          this.fileList.push(values[i]);
        }
      }
      this.srcList = this.fileList.map((x) => x.file_url);
      this.$emit("change", this.fileList);
    },
  },
};
</script>

<style  scoped>
.tip {
  width: 100%;
}
.list {
  width: 100%;
  display: flex;
  flex-flow: wrap;
}

.list .image {
  margin: 0 10px 10px 0;
  width: 100px;
  height: 100px;
  border-radius: 5px;
  position: relative;
}

.list .image .el-image {
  border-radius: 5px;
}

.list .image .el-icon {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #f56c6c;
  display: none;
}

.list .image:hover .icon-del.el-icon {
  display: block;
}
/* 主轮播图标签 */
.list .image .cover_tag {
  position: absolute;
  color: white;
  font-size: 12px;
  line-height: 12px;
  background: var(--el-color-primary);
  left: 0;
  padding: 2px;
}
/* 拖拽组 */
.draggable_group {
  display: flex;
  flex-flow: wrap;
}
</style>