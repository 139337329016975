<!--
 * @Description: 上传图片
 * @Author: 琢磨先生
 * @Date: 2022-05-31 10:47:47
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-07-26 11:59:19
-->
<template>
  <el-button
    size="small"
    type="primary"
    @click="dialogVisible = true"
    round
    icon="plus"
    >上传图片</el-button
  >
  <el-dialog
    title="上传图片"
    v-model="dialogVisible"
    width="650px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    custom-class="psd-dialog"
    @close="dialogClose"
  >
    <div class="help-block">支持png,jpg,jpeg格式图片，且图片大小不能超过8M</div>
    <el-upload
      ref="upload"
      class="upload"
      drag
      action=""
      accept=".jpg,.jpeg,.png,.gif"
      :http-request="upload"
      multiple
      :before-remove="
        () => {
          return false;
        }
      "
      :before-upload="beforeUpload"
    >
      <el-icon class="el-icon--upload"><upload-filled /></el-icon>
      <div class="el-upload__text">拖拽至此 或<em>点击</em>上传</div>
      <template #tip> </template>
    </el-upload>

    <template #footer>
      <!-- <el-button>关闭</el-button> -->
    </template>
  </el-dialog>
</template>

<script>
// import ObsClient from "@/obs/esdk-obs-browserjs.3.22.3.min.js";
import ObsClient from "esdk-obs-browserjs/src/obs";

import md5_util from "@/utils/md5_util.js";
import img_utils from "@/utils/img_utils";

export default {
  data() {
    return {
      dialogVisible: false,
    };
  },
  emits: ["close", "change"],
  props: ["folder", "psd_type"],
  created() {
    var settings = this.$store.getters.getSettings;
    this.bucketName = settings.obs_bucket_name;
    this.obs_cdn = settings.obs_cdn;
    this.obs = new ObsClient({
      access_key_id: settings.obs_key_id,
      secret_access_key: settings.obs_access_key,
      server: settings.obs_server,
      timeout: 60 * 5,
    });
  },
  methods: {
    beforeUpload(file) {
      var isR5M = file.size > 1024 * 1024 * 5;
      if (isR5M) {
        return false;
      }
      return new Promise((resolve) => {
        let img = new Image();
        img.onload = function () {
          file.width = img.width;
          file.height = img.height;
          resolve(file);
        };
        img.src = URL.createObjectURL(file);
      });
    },
    /**
     * 执行上传
     */
    upload: async function (ctx) {
      ctx.onProgress({ percent: 0 });

      var _f = ctx.file;
      //大于2M进行图片压缩
      if (ctx.file.size > 1024 * 1024 * 2) {
        _f = await img_utils.compress(ctx.file);
        _f.name = ctx.file.name;
      }

      var model = {
        height: ctx.file.height,
        width: ctx.file.width,
        size: ctx.file.size,
        psd_type: 1,
        name: ctx.file.name,
        ext: `.${_f.type.replace("image/", "")}`,
      };
      if (this.folder && this.folder.id) {
        model.father_id = this.folder.id;
      }

      //获取md5值
      var md5 = await md5_util.fileMd5(_f);
      model.md5 = md5;

      var res = await this.$http.get(`seller/v1/psd/file/dtl?md5=${md5}`);
      if (res.code == 0) {
        if (res.data) {
          ctx.onSuccess();
          this.$emit("change", res.data);
          return;
        }
      }
      res = await this.$http.get("/common/filename");
      if (res.code == 0) {
        var result = await this.obs.putObject({
          Bucket: this.bucketName,
          Key: res.data + model.ext,
          SourceFile: _f,
          ProgressCallback: (transferredAmount, totalAmount) => {
            var percent = (transferredAmount * 100.0) / totalAmount;
            ctx.onProgress({
              percent: percent,
            });
          },
        });
        if (result.CommonMsg.Status < 300) {
          var cdn_url = `${this.obs_cdn}/${res.data}${model.ext}`;
          model.file_url = cdn_url;
          this.$http.post("seller/v1/psd/file/add", model).then((res) => {
            if (res.code == 0) {
              ctx.onSuccess();
              this.$emit("change", res.data);
            } else {
              ctx.onError();
            }
          });
        }
      }
    },
    /**
     *
     */
    dialogClose() {
      this.$refs.upload.clearFiles();
      this.$emit("close");
    },
  },
};
</script>
<style >
.psd-dialog .el-dialog__body {
  padding-top: 0px !important;
}
</style>

<style  >
.upload {
  width: 100%;
}
.upload .el-upload {
  width: 100%;
  margin-top: 10px;
}
.upload .el-upload .el-upload-dragger {
  width: 100%;
}

.upload .el-icon.el-icon--close {
  display: none;
}

/* .psd-dialog .el-upload-list__item {
  padding: 0 10px;
} */
.psd-dialog .el-upload-list__item-thumbnail {
  width: 50px;
  height: 50px;
}
</style>